import { defineStore } from 'pinia';
import axios from "axios";
import router from './router';
import { format, utcToZonedTime } from 'date-fns-tz';

let headers = {
  'NSAppId': 'NSApiApp8282',
  'NSAppToken': 'temp'
}
  
function UserAuth() {
  return {
    isAuthenticated: false,
    username: "",
    role:"",
    token: "",
    showValidationMessage: false,
    browserName: "",
    ipAddress: "",
    loginDatetime: 0,
    isValid: function () {
      console.log("config valid min -- " + process.env.VUE_APP_SESSION_VALID_MIN);
      let currentdatetime = new Date();
      if (currentdatetime.getTime() - parseInt(process.env.VUE_APP_SESSION_VALID_MIN) * 60 * 1000 < this.loginDatetime) {
        this.isAuthenticated = true;
        this.loginDatetime = currentdatetime.getTime();
      } else {
        this.isAuthenticated = false;
        this.loginDatetime = 0;
        this.token = "";
      }
      return this.isAuthenticated;
    },
    convert: function (stringValue) {
      let temp = JSON.parse(stringValue);
      if (temp != null) {
        this.isAuthenticated = temp.isAuthenticated;
        this.username = temp.username;
        this.role = temp.role;
        this.showValidationMessage = temp.showValidationMessage;
        this.browserName = temp.browserName;
        this.ipAddress = temp.ipAddress;
        this.loginDatetime = temp.loginDatetime;
        this.token = temp.token;
      }
    }
  }
}

export const useAuthStore = defineStore('auth', {
  state: () => {
    // first get locally stored user auth
    let userStringFromLocal = localStorage.getItem("userAuthState");
    //console.log("user auth from local storage  -- " + userStringFromLocal);

    // convert string to UserAuth object
    let userFromLocal = new UserAuth();
    userFromLocal.convert(userStringFromLocal);

    // check if the locally stored user auth is valid
    if (userFromLocal != null && userFromLocal.isValid()) {
      //console.log("user valid session exists -- " + JSON.stringify(userFromLocal));
      console.log("user valid session date exists -- " + userFromLocal.loginDatetime);
      // set header value from session
      headers.NSAppToken = userFromLocal.token;
      // save user auth to local storage
      localStorage.setItem("userAuthState", JSON.stringify(userFromLocal));
      //this.isAuthenticated = true;
    } else {
      console.log("user is not logged in!");
      // nullify local storage
      localStorage.setItem("userAuthState", null);

    }
    return userFromLocal;
  },
  actions: {
    async login(cred) {
      const userData = {
        username: cred.username,
        password: cred.password
      };

      await axios.post(process.env.VUE_APP_API_URL + '/api/auth/signin', userData)
        .then(async (response) => {
          console.log("after login", response.data);
          this.isAuthenticated = true;
          this.username = response.data.user;
          this.role = response.data.role;
          this.showValidationMessage = false;
          await this.fetchIpAddress();
          this.detechBrowser();
          this.token = response.data.token;
          headers.NSAppToken = this.token;
          //console.log("headers", headers);

          if (this.role == "BOOKCADMIN") {
            router.push("/cafe");
          } else {
            router.push('/pay');
          }

          // save user auth to local storage
          let userAuth = new UserAuth();
          userAuth.isAuthenticated = this.isAuthenticated;
          userAuth.username = this.username;
          userAuth.role = this.role;
          userAuth.token = this.token;
          userAuth.showValidationMessage = this.showValidationMessage;
          userAuth.loginDatetime = new Date().getTime();
          userAuth.ipAddress = this.ipAddress;
          userAuth.browserName = this.browserName;
          localStorage.setItem("userAuthState", JSON.stringify(userAuth));
          console.log("logged in successfully -- " + JSON.stringify(userAuth));
        })
        .catch(error => {
          console.error("Axios Login failed", error);
          this.showValidationMessage = true;
        });
    },
    logout() {
      this.isAuthenticated = false;
      this.showValidationMessage = false;
      this.username = "";
      this.role = "";
      this.token = "";
      // nullify local storage
      localStorage.setItem("userAuthState", null);
      return new UserAuth();
    },
    async fetchIpAddress() {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        this.ipAddress = response.data.ip;
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    },
    detechBrowser() {
      const userAgent = navigator.userAgent;

      if (userAgent.indexOf('Chrome') !== -1) {
        this.browserName = 'Chrome';
      } else if (userAgent.indexOf('Firefox') !== -1) {
        this.browserName = 'Firefox';
      } else if (userAgent.indexOf('Safari') !== -1) {
        this.browserName = 'Safari';
      } else if (userAgent.indexOf('Opera') !== -1 || userAgent.indexOf('OPR') !== -1) {
        this.browserName = 'Opera';
      } else if (userAgent.indexOf('Edge') !== -1) {
        this.browserName = 'MS Edge';
      } else if (userAgent.indexOf('Trident') !== -1) {
        this.browserName = 'Internet Explorer';
      } else {
        this.browserName = 'Unknown';
      }
    }
  },
});

export const useTransactionStore = defineStore('transaction', {
  state: () => ({
    transactions: [],
    memberTransactions: [],
    items: [],
    memberId: "",
    balance: 0,
    isValidPhoneNumber: true,
    didPay: false,
    isGetTodayTransactionsCalled: false, // call getTodayTransactions() only once
    orderInProgress: []
  }),
  actions: {
    // Add the latest transaction in an array after pay, deposit, or refund
    async getTransaction(memberId) {
      await axios.get(process.env.VUE_APP_API_URL + '/api/transaction', { params: { memberId } , headers: headers})
        .then(response => {
          const responseTransactions = response.data;
          //console.log ("before formatting", responseTransactions);
          // fix date format
          const formattedTransactions = responseTransactions.map((transaction) => {
            const zonedTime = utcToZonedTime(transaction.transtime, 'America/Chicago');
            return {
              ...transaction,
              transtime: format(zonedTime, 'yyyy-MM-dd hh:mm:ss a')
            };
          });

          this.transactions.unshift(...formattedTransactions);
        })
        .catch(error => {
          console.error("Failed to load transaction", error);
        });
    },
    // Fetch today's transactions
    async getTodayTransactions() {
      if (!this.isGetTodayTransactionsCalled) {
        await axios.get(process.env.VUE_APP_API_URL + '/api/transactions', {headers: headers})
          .then(response => {
            console.log ("getTodayTransactions begins");
            this.transactions = [];
            const responseTransactions = response.data;
            const formattedTransactions = responseTransactions.map((transaction) => {
              //console.log ("getTodayTransactions", transaction.transtime);
              let zonedTime = utcToZonedTime('1900-01-01T00:00:01.807Z', 'America/Chicago');
              if (transaction.transtime != null)
                zonedTime = utcToZonedTime(transaction.transtime, "America/Chicago");
              //console.log ("getTodayTransactions", zonedTime);
              return {
                ...transaction,
                transtime: format(zonedTime, 'yyyy-MM-dd hh:mm:ss a')
              };
            });

            this.transactions.push(...formattedTransactions);
            console.log ("getTodayTransactions after formatting");
            this.isGetTodayTransactionsCalled = true;
          })
          .catch(error => {
            console.error("Failed to load transactions", error);
          });
      }
    },
    // Get transactions for inquiry
    async getMemberTransactions() {
      await axios.get(process.env.VUE_APP_API_URL + '/api/member/transactions', { params: { memberId: this.memberId}, headers: headers })
        .then(response => {
          this.memberTransactions = [];
          // const transactions = response.data.recordset.recordset;
          const responseTransactions = response.data;
          //console.log ("responseTransactions", responseTransactions);
          const formattedTransactions = responseTransactions.map((transaction) => {
              const zonedTime = utcToZonedTime(transaction.transtime, "America/Chicago");
              return {
                ...transaction,
                transtime: format(zonedTime, 'yyyy-MM-dd hh:mm:ss a')
              };
            });
            this.memberTransactions.push(...formattedTransactions);
        })
        .catch(error => {
          console.error("Failed to load member transactions", error);
        });
    },    
    // Clear transactions in inquiry page
    async clearMemberTransactrions() {
      this.memberTransactions = [];
      this.balance = 0;
    },
    // Validate barcode
    async validateBarcode(barcodeInfo, validateEmail = true, isInquiry = false) {
      const phoneNumber = barcodeInfo.substring(0, 10);
      const firstFourEmailChar = barcodeInfo.substring(10, barcodeInfo.length);

      if (phoneNumber && firstFourEmailChar && validateEmail || phoneNumber && !validateEmail) {
        await axios.get(process.env.VUE_APP_API_URL + '/api/member/id', { params: { phoneNumber: phoneNumber }, headers: headers })
          .then(response => {
            //console.log (response.data);
            this.memberId = response.data.memberId;

            if (this.memberId !== "NONE") {
              this.isValidPhoneNumber = true;
              if (validateEmail) {
                this.validateEmail(firstFourEmailChar, isInquiry);
              }
            } else {
              this.isValidPhoneNumber = false;
            }
          })
          .catch(error => {
            console.error("Failed to validate phone number", error);
            this.isValidPhoneNumber = false;
          });
      } else {
        this.isValidPhoneNumber = false;
      }
    },
    // Validate first four email char
    async validateEmail(firstFourEmailChar, isInquiry = false) {
      await axios.get(process.env.VUE_APP_API_URL + '/api/email', { params: { memberId: this.memberId }, headers: headers })
        .then(response => {
          if (firstFourEmailChar.toLowerCase() === response.data.first_four_char_email.toLowerCase()) {
            this.isValidPhoneNumber = true;
            if(isInquiry) {
              this.getMemberTransactions();
            }
          } else {
            this.isValidPhoneNumber = false;
          }
        })
        .catch(error => {
          console.error("Failed to validate phone number", error);
          this.isValidPhoneNumber = false;
        });
    },
    // Get Balance 
    async getBalance(barcodeInfo, isInquiry = false) {
      await this.validateBarcode(barcodeInfo, true, isInquiry);
      if (this.isValidPhoneNumber) {
        await axios.get(process.env.VUE_APP_API_URL + '/api/balance', { params: { memberId: this.memberId }, headers: headers })
          .then(response => {
            this.balance = response.data.balance;
            this.didPay = false;
          })
          .catch(error => {
            console.error("Failed to fetch memberId ", error);
          });
      }
    },
    // Make a payment ($2 Meal)
    async pay(barcodeInfo) {
      await this.validateBarcode(barcodeInfo);
      if (this.isValidPhoneNumber) {
        await axios.post(process.env.VUE_APP_API_URL + '/api/member/pay', { memberId: this.memberId, username: useAuthStore().username, ipAddress: useAuthStore().ipAddress, browserName: useAuthStore().browserName }, {headers: headers})
          .then(() => {
            console.log("Paid for member ", this.memberId);
            this.getTransaction(this.memberId);
            this.didPay = true;
          })
          .catch(error => {
            console.error("Failed to make a payment", error);
            this.didPay = false;
          });
      }
    },
    async getAllItemsAndPrices() {
      await axios.get(process.env.VUE_APP_API_URL + '/api/item-prices', {headers: headers})
        .then(response => {
          if (response && response.data) {
            this.items = response.data;
          }
        })
        .catch(error => {
          console.error("Failed to fetch items", error);
        });
    },
    // Make a payment (Cafe)
    async payCafe(barcodeInfo, item, paymentMethod, orderNumber) {
      try {
        if (paymentMethod === "SCAN") {
          await this.validateBarcode(barcodeInfo);
          if (this.isValidPhoneNumber) {
            await axios.post(process.env.VUE_APP_API_URL + '/api/member/pay-cafe-barcode', {
              memberId: this.memberId,
              transType: "DEBIT",
              item,
              paymentMethod: "SCAN",
              username: useAuthStore().username,
              ipAddress: useAuthStore().ipAddress,
              browserName: useAuthStore().browserName,
              orderNumber,
              orderStatusCode: orderNumber === "SKIP" ? "DONE" : "IP"
            }, {headers: headers})
            .catch(error => {
              console.error("Failed to make a payment", error);
            });
          }
        } else if (paymentMethod === "CC" || paymentMethod === "CASH") {
          await axios.post(process.env.VUE_APP_API_URL + '/api/member/pay-cafe-cash', {
            item,
            paymentMethod: "CASH",
            username: useAuthStore().username,
            ipAddress: useAuthStore().ipAddress,
            browserName: useAuthStore().browserName,
            orderNumber,
            orderStatusCode: orderNumber === "SKIP" ? "DONE" : "IP"
          }, {headers: headers})
          .catch(error => {
            console.error("Failed to make a payment", error);
          });
        }
      } catch (error) {
        console.error("Failed to make a payment:", paymentMethod, error);
      }
    },
    async getOrdersInProgress() {
      await axios.get(process.env.VUE_APP_API_URL + '/api/member/orders-in-progress', {headers: headers})
        .then((response) => {
          //console.log (response.data);
          this.orderInProgress = response.data;
        })
        .catch((error) => {
          console.error("Failed to load orders in progress", error);
        });
    },
    async completeOrderStatus(transId) {
      await axios.post(process.env.VUE_APP_API_URL + '/api/member/complete-cafe-order', { transId }, {headers: headers})
        .then(() => {
          console.log("Order Completed ", transId);
        })
        .catch(error => {
          console.error("Failed to update order status code", error);
        });
    },
    // Add funds
    async deposit(barcodeInfo, amount, transType) {
      await this.validateBarcode(barcodeInfo, false);
      if (this.isValidPhoneNumber && amount <= 500) {
        await axios.post(process.env.VUE_APP_API_URL + '/api/member/deposit', { memberId: this.memberId, amount: amount, transType: transType, username: useAuthStore().username, ipAddress: useAuthStore().ipAddress, browserName: useAuthStore().browserName }, {headers: headers})
          .then(() => {
            console.log("Deposit for member ", this.memberId);
            this.getTransaction(this.memberId);
          })
          .catch(error => {
            console.error("Failed to deposit", error);
          });
      }
    },
    // Refund the latest transaction (main meal $2)
    async refund() {
      if (this.isValidPhoneNumber) {
        await axios.post(process.env.VUE_APP_API_URL + '/api/member/refund', { memberId: this.memberId, username: useAuthStore().username, ipAddress: useAuthStore().ipAddress, browserName: useAuthStore().browserName }, {headers: headers})
          .then(() => {
            console.log("Refund for member ", this.memberId);
            this.getTransaction(this.memberId);
            this.memberId = "";
          })
          .catch(error => {
            console.error("Failed to refund", error);
          });
      }
    },
    async clearDailyTransactrions() {
      this.transactions = [];
      this.balance = 0;
    },
    // Refund a given transaction based on id 
    async refundOneTransaction(transId, memberId) {
      await axios.post(process.env.VUE_APP_API_URL + '/api/member/refundById', { memberId: memberId, transId: transId, username: useAuthStore().username, ipAddress: useAuthStore().ipAddress, browserName: useAuthStore().browserName }, {headers: headers})
        .then(() => {
          console.log("Refund for member: ", memberId, "transaction id: ", transId);
          this.isGetTodayTransactionsCalled = false;
          this.getTodayTransactions();
        })
        .catch(error => {
          console.error("Failed to refund", error);
        });
    }
  },
});

export const useSummaryStore = defineStore('dailySummary', {
  state: () => ({
    summaryData: [],
  }),
  actions: {
  // Add the latest transaction in an array after pay, deposit, or refund
    async getSummary(dateValue) {
      //console.log(dateValue);
      await axios.get(process.env.VUE_APP_API_URL + '/api/report/tx/dailytotal', { params: { "summaryDate": dateValue }, headers: headers })
        .then(response => {
          this.summaryData = [];
          const summaryRecordSet = response.data;
          const formattedSummary = summaryRecordSet.map((summaryRecord) => {
            const zonedTime = utcToZonedTime(summaryRecord.summarydate, 'UTC');
            // Format the price above to USD using the locale, style, and currency.
            const USDollar = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            });
            summaryRecord.SummaryDate = format(zonedTime, 'MM-dd-yyyy');
            summaryRecord.DailyTotalDebitAmount = USDollar.format(summaryRecord.dailytotaldebitamount);
            summaryRecord.DailyTotalCreditAmount = USDollar.format(summaryRecord.dailytotalcreditamount);
            summaryRecord.DailyTotalPayRefundAmount = USDollar.format(summaryRecord.dailytotalrefundamount);
            summaryRecord.DailyTotalDepositRefundAmount = USDollar.format(summaryRecord.dailytotaldepositrefamount);
            summaryRecord.DailyComputedDebitAmount = USDollar.format(parseFloat(summaryRecord.dailytotaldebitamount) - parseFloat(summaryRecord.dailytotalrefundamount));
            summaryRecord.DailyComputedCreditAmount = USDollar.format(parseFloat(summaryRecord.dailytotalcreditamount) - parseFloat(summaryRecord.dailytotaldepositrefamount));
            summaryRecord.DailyBalance = USDollar.format(summaryRecord.dailybalance);
            summaryRecord.DailyActiveMembers = summaryRecord.dailyactivemembers;
            summaryRecord.DailyNewMembers = summaryRecord.dailynewmembers;
            summaryRecord.GrandTotalActiveMembers = summaryRecord.grandtotalactivemembers;
            summaryRecord.GrandTotalTransBalance = USDollar.format(summaryRecord.grandtotaltransbalance);
            summaryRecord.GrandTotalMemberBalance = USDollar.format(summaryRecord.grandtotalmemberbalance);
            summaryRecord.GrandTotalDebitAmount = USDollar.format(summaryRecord.grandtotaldebitamount);
            summaryRecord.GrandTotalCreditAmount = USDollar.format(summaryRecord.grandtotalcreditamount);
            summaryRecord.TransPoint = summaryRecord.transpoint;
            return summaryRecord;
          });
          this.summaryData.unshift(...formattedSummary);
        })
        .catch(error => {
          console.error("Failed to load daily summary", error);
        })
    },
    async clearSummary() {
      this.summaryData = [];
      this.dateValue = "";
    },    
    async computeSummaryForToday() {
      console.log("compute summary for today");
      await axios.get(process.env.VUE_APP_API_URL + '/api/cron/computedailysummary', { params: { endOfDay: 'Y' }, headers: headers })
        .then(() => {
          console.log("compute summary for today worked!");
        })
        .catch(error => {
          console.error("Failed to compute summary for today", error);
        });
    },
  },
});

export const useRegisterStore = defineStore('register', {
  state: () => ({
    isRegisterd: false,
  }),
  actions: {
  async register(phoneNumber, engName, korName, email) {
    await axios.post(process.env.VUE_APP_API_URL + '/api/member/register', { phoneNumber, engName, korName, email }, {headers: headers})
      .then(response => {
          console.log("Successfully registered", response);
          this.isRegisterd = true;
      })
      .catch(error => {
        console.error("Failed to register", error);
        this.isRegisterd = false;
      })
    },
  },
});


