export const PAYMENT_TYPES = {
    BARCODE: "BARCODE",
    CASH: "CASH",
    CC: "CC",
};
  
export const BARCODE_TYPES = {
    ITEM: "ITEM",
    ORDER_NUMBER: "ORDER_NUMBER",
    USER: "USER",
    DISABLED: "DISABLED",
};