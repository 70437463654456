<template>
  <div class="receipt-container">
    <h2>The Stump Receipt</h2>
    <div class="receipt-header">
      <div>Order #{{ currentOrder.orderNumber === "SKIP" ? "NA" : currentOrder.orderNumber }}</div>
      <div>Date: {{ new Date().toLocaleDateString() }}</div>
    </div>
    <div class="receipt-body">
      <ul>
        <li v-for="item in currentOrder.scannedItem" :key="item.item" class="receipt-item">
          <span class="col-4">{{ item.item }}</span>
          <span class="col-6">x{{ item.count }}</span>
          <span>${{ formatPrice(item.price) }}</span>
        </li>
      </ul>
    </div>
    <div class="receipt-total">
      <strong>Total:</strong>
      <span>${{ formatPrice(currentOrder.total) }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Receipt",
  props: {
    currentOrder: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatPrice(value) {
      return value ? value.toFixed(2) : "0.00";
    },
  },
});
</script>

<style scoped>
.receipt-container {
  font-family: "Courier New", Courier, monospace;
  max-width: 350px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 15px;
  margin: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.receipt-container h2 {
  text-align: center;
  border-bottom: 1px dashed #bbb;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.receipt-header,
.receipt-total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.receipt-body ul {
  list-style: none;
  padding: 0;
}

.receipt-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.receipt-item span {
  display: block;
}

.receipt-total strong,
.receipt-total span {
  font-size: 20px;
}
</style>
