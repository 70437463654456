<template>
  <div class="order-container">
    <h3 class="order-header">Order List</h3>
    <table class="table table-bordered table-text">
      <thead>
        <tr>
          <th
            v-for="(field, index) in titles"
            :key="field"
            :class="isCenterAligned(index)"
            :style="{ width: getColumnWidth(index) }"
          >
            {{ field }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(order, index) in scannedItems" :key="index" :id="'order' + index">
          <td class="text-center">{{ index + 1 }}</td>
          <td>
            <div v-for="(item, orderIndex) in order.item" :key="orderIndex" :id="'item' + orderIndex">
              {{ item.name }}({{ item.count }})
            </div>
          </td>
          <td class="text-center">{{ order.ordernumber }}</td>
          <td class="text-center">
            <button @click="removeOrder(index)" class="complete-btn">주문 완료</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// import "bootstrap/dist/css/bootstrap.min.css";
import { defineComponent, ref, computed, onMounted } from "vue";
import { useTransactionStore } from "@/store";

export default defineComponent({
  name: "Order",
  setup() {
    // State variables
    const transactionStore = useTransactionStore();
    const titles = ["#", "Item", "Order Number", "Option"];
    const scannedItems = ref([]);

    const itemList = computed({
      get: () => transactionStore.items,
      set: (newValue) => (transactionStore.items = newValue),
    });

    const completeOrderStatus = async (transId) => {
      await transactionStore.completeOrderStatus(transId);
    };

    const orderInProgress = computed({
      get: () => transactionStore.orderInProgress,
      set: (newValue) => (transactionStore.orderInProgress = newValue),
    });

    const formatOrders = () => {
      const formatOrders = [];

      orderInProgress.value.forEach((order) => {
        const orderNumber = order["ordernumber"];
        if (orderNumber !== "SKIP") {
          const transId = order["transid"];
          const itemName = itemList.value.find((item) => item.itemnumber === order["name"]).itemdesc;
          const existingOrderIndex = formatOrders.findIndex((oneOrder) => oneOrder.ordernumber === orderNumber);
          // console.log ("transId", transId);
          // console.log ("itemName", itemName);
          // console.log ("orderNumber", orderNumber);
          // console.log ("existingOrderIndex", existingOrderIndex);

          if (existingOrderIndex !== -1) {
            const existingItemIndex = formatOrders[existingOrderIndex].item.findIndex(
              (item) => item.name.toUpperCase() === itemName.toUpperCase()
            );
            if (existingItemIndex !== -1) {
              formatOrders[existingOrderIndex].item[existingItemIndex].count++;
            } else {
              formatOrders[existingOrderIndex].item.push({ name: itemName, count: 1 });
            }
            formatOrders[existingOrderIndex].transid.push(order.transid);
          } else {
            formatOrders.push({
              ordernumber: orderNumber,
              item: [{ name: itemName, count: 1 }],
              transid: [transId],
              transtime: new Date(order.transtime).toLocaleTimeString("en-US", { hour12: false }),
            });
          }
        }
      });

      formatOrders.sort(
        (a, b) =>
          new Date(new Date().toDateString() + " " + a.transTime) -
          new Date(new Date().toDateString() + " " + b.transTime)
      );

      return formatOrders;
    };

    onMounted(async () => {
      await transactionStore.getAllItemsAndPrices();
      await transactionStore.getOrdersInProgress();
      scannedItems.value = formatOrders();
      localStorage.setItem("scannedOrders", JSON.stringify(scannedItems.value));
    });

    return {
      titles,
      scannedItems,
      itemList,
      orderInProgress,
      completeOrderStatus,
    };
  },
  mounted() {
    // Establish WebSocket connection when the component is mounted
    this.websocket = new WebSocket(process.env.VUE_APP_WS_URL);

    // Event listener for WebSocket connection opened
    this.websocket.onopen = () => {
      console.log("WebSocket connection established");
    };

    // Event listener for WebSocket messages received
    this.websocket.onmessage = (event) => {
      const order = JSON.parse(event.data);
      console.log("Order received from server:", order);

      const orderNumber = order["orderNumber"];

      if (orderNumber !== "SKIP") {
        const itemName = this.itemList.find((item) => item.itemnumber === order.item[0].name).itemdesc;
        const existingOrderIndex = this.scannedItems.findIndex((item) => item.ordernumber === orderNumber);

        if (existingOrderIndex !== -1) {
          const existingItemIndex = this.scannedItems[existingOrderIndex].item.findIndex(
            (item) => item.name.toUpperCase() === itemName.toUpperCase()
          );
          if (existingItemIndex !== -1) {
            this.scannedItems[existingOrderIndex].item[existingItemIndex].count++;
          } else {
            this.scannedItems[existingOrderIndex].item.push({ name: itemName, count: 1 });
          }
          this.scannedItems[existingOrderIndex].transid.push(order.item[0].transId);
        } else {
          this.scannedItems.push({
            ordernumber: orderNumber,
            item: [{ name: itemName, count: 1 }],
            transid: [order.item[0].transId],
            transtime: order.transTime,
          });
        }
        localStorage.setItem("scannedOrders", JSON.stringify(this.scannedItems));
      }
    };

    window.addEventListener("load", () => {
      const storedOrders = localStorage.getItem("scannedOrders");
      if (storedOrders && storedOrders.length) {
        this.scannedItems = JSON.parse(storedOrders);
      }
    });

    // Event listener for WebSocket connection closed
    this.websocket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    // Event listener for WebSocket connection errors
    this.websocket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
  },
  methods: {
    async removeOrder(index) {
      const transIds = this.scannedItems[index].transid;
      for (let i = 0; i < transIds.length; i++) {
        await this.completeOrderStatus(transIds[i]);
      }
      this.scannedItems.splice(index, 1);
      localStorage.setItem("scannedOrders", JSON.stringify(this.scannedItems));
    },
    isCenterAligned(index) {
      return index === 0 || index === 2 || index === 3 ? "text-center" : "";
    },
    getColumnWidth(index) {
      const widths = ["60px", "", "190px", "164px"];
      return widths[index];
    },
  },
});
</script>

<style scoped>
.order-container {
  margin: 20px 40px;
  width: 100%;
}

.order-header {
  margin-bottom: 30px;
}

.table-text {
  font-size: 25px;
  font-weight: 500;
  vertical-align: middle;
}

.complete-btn {
  margin-top: 0;
  border-radius: 10px;
}
</style>
