<template>
  <nav class="leftnav" v-if="isAuth">
    <button @click="openCloseNav()" class="menu-btn">&#9776;</button>
    <div id="menubar" class="leftnav-container">
      <ul class="leftnav-ul">
        <li class="leftnav-item" v-if="myRole === 'ADMIN'">
          <router-link class="nav-link" to="/pay">Pay</router-link>
        </li>
        <li class="leftnav-item" v-if="myRole === 'ADMIN'">
          <router-link class="nav-link" to="/inquiry">Inquiry</router-link>
        </li>
        <li class="leftnav-item" v-if="myRole === 'ADMIN'">
          <router-link class="nav-link" to="/deposit">Deposit</router-link>
        </li>
        <li class="leftnav-item" v-if="myRole === 'ADMIN'">
          <router-link class="nav-link" to="/register">Register</router-link>
        </li>
        <li class="leftnav-item" v-if="myRole === 'ADMIN'">
          <router-link class="nav-link" to="/search">Search</router-link>
        </li>
        <li class="leftnav-item" v-if="myRole === 'ADMIN'">
          <router-link class="nav-link" to="/dailysummary">Daily Summary</router-link>
        </li>
        <li class="leftnav-item" v-if="myRole === 'BOOKCADMIN'">
          <router-link class="nav-link" to="/cafe">Cafe</router-link>
        </li>
        <li class="leftnav-item" v-if="myRole === 'BOOKCADMIN'">
          <router-link class="nav-link" to="/order">Order</router-link>
        </li>
        <li class="leftnav-item" v-if="myRole === 'BOOKCADMIN'">
          <router-link class="nav-link" to="/dailysummarycafe">Daily Summary</router-link>
        </li>
        <!-- <li class="leftnav-item">
          <router-link class="nav-link" to="/member-management"
            >Member Management</router-link
          >
        </li>
        <li class="leftnav-item">
          <router-link class="nav-link" to="/user-management"
            >User Management</router-link
          >
        </li> -->
        <li class="leftnav-item">
          <router-link class="nav-link" to="/" @click="logout">Log out</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { useAuthStore } from "@/store";
import { ref, watch } from "vue";

export default {
  name: "leftnav",
  setup() {
    const authStore = useAuthStore();
    let isAuth = ref(false);
    let myRole = ref("USER");

    // this kicks in when the value changes
    watch(
      () => authStore.isAuthenticated,
      (newValue) => {
        isAuth.value = newValue;
        if (newValue) myRole.value = authStore.role;
        else myRole = ref("USER");
      }
    );

    console.log("myRole: ", myRole);
    // this kicks in on the first time load or browser refresh
    // we will check the previous login from local storage
    isAuth.value = authStore.isAuthenticated;
    if (authStore.isAuthenticated) {
      myRole.value = authStore.role;
    } else {
      myRole = ref("USER");
    }

    return {
      isAuth,
      myRole,
      logout: authStore.logout,
    };
  },
  methods: {
    /* Set the width of the side navigation to 250px */
    async openCloseNav() {
      if (document.getElementById("menubar").offsetWidth === 0 )
        document.getElementById("menubar").style.width = "250px";
      else 
        document.getElementById("menubar").style.width = "0";
    },
  },
};
</script>

<style scoped>
body {
  margin: 0;
}

.menu-btn {
  font-size: 20px;
  background-color: #2f668a;
}

.leftnav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.leftnav-container {
  height: 100vh;
  width: 0;
  background-color: #2f668a;
  overflow-x: hidden;
  transition: 0.5s;
}

.leftnav-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.leftnav-item {
  margin: 30px;
  font-size: 20px;
  font-weight: 500;
}

.leftnav .closebtn {
  font-size: 36px;
  text-decoration: none;
}
.nav-link {
  text-decoration: none;
  color: #ffffff;
}
</style>
