<template>
  <div class="balance-inquiry-container">
    <div class="inquiry-header">
      <h3>Daily Transactions</h3>
      <button @click="searchTrans()" class="search-btn">기록 찾기</button>
      <button @click="clearDailyTransactions()" class="clear-btn">기록 지우기</button>
    </div>

    <h4>Transaction History</h4>
    <table class="table table-bordered table-striped table-text">
      <thead>
        <tr>
          <th v-for="field in titles" :key="field">
            {{ field }}
            <i class="bi bi-sort-alpha-down" aria-label="Sort Icon"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in transactionData" :key="item">
          <td v-for="field in fields" :key="field">{{ item[field] }}</td>
          <td class="text-center">
            <div v-if="item.transtype === 'CREDIT' || item.transtype === 'DEBIT'">
              <button @click="refundTransId(item.transid, item.memberid)" class="refund-btn">환불</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted } from "vue";
import { useTransactionStore } from "@/store";
import "bootstrap/dist/css/bootstrap.min.css";

export default defineComponent({
  name: "DailyTransactions",
  setup() {
    const titles = ["Name", "Time", "Type", "Amount", "Balance ($)", "Action"];
    const fields = ["koreanname", "transtime", "transtype", "transamount", "runningbalance"];
    const transactionStore = useTransactionStore();

    const dailyTransactions = computed({
      get: () => transactionStore.transactions,
      set: (newValue) => (transactionStore.transactions = newValue),
    });
    const clearDailyTransactions = async () => {
      await transactionStore.clearDailyTransactrions();
    };
    const searchTrans = async () => {
      transactionStore.isGetTodayTransactionsCalled = false;
      await transactionStore.getTodayTransactions();
    };
    const refundTransId = async (transId, memberId) => {
      console.log ("transId", transId);
      console.log ("memberId", memberId);
      await transactionStore.refundOneTransaction(transId, memberId);
    };
    onMounted(() => {
      //phoneNumberField.value.focus();
    });

    return {
      clearDailyTransactions,
      searchTrans,
      refundTransId,
      fields,
      titles,
      transactionData: dailyTransactions,
    };
  },
  methods: {
    async clearHistory() {
      this.clearDailyTransactions();
    },
  },
});

/**
 * we initially add a browser session history record with current URL
 * when browser back or forward button is clicked, it will force a forward/go.(1) which was just added
 */
history.pushState(null, null, location.href);
window.onpopstate = function () {
  history.go(1);
};
</script>

<style scoped>
.balance-inquiry-container {
  width: 100%;
  padding: 20px;
}
.cont-input {
  border: none;
  outline: none;
  caret-color: transparent;
  color: #fff;
}
.search-btn {
  background-color: blue;
  margin-top: 0px;
  margin-bottom: 10px;
  width: 200px;
}
.refund-btn {
  background-color: green;
  margin-top: 0px;
  margin-bottom: 10px;
  width: 100px;
}
.clear-btn {
  background-color: #dc2f2f;
  margin-top: 0px;
  margin-bottom: 10px;
  width: 200px;
  border-radius: 4px;
  padding: 10px 20px;
  color: #fff;
  border: none;
}
.inquiry-header {
  display: flex;
  justify-content: space-between;
  width: 80%;
}
.input-row {
  display: flex;
  margin-bottom: 10px;
  height: 1px;
}
.table-text {
  font-size: 20px;
}
</style>
