<template>
  <div class="cafe-popup">
    <div class="cafe-popup-content">
      <h3 id="cafePopupTotal" class="cafe-popup-total">Total: ${{ total.toFixed(2) }}</h3>

      <div v-if="barcodeType === 'ORDER_NUMBER'">
        <h4 class="cafe-popup-header">주문 번호를 스캔해주세요.</h4>
        <button id="orderNumberSkipButton" @click="skipORderNumber">건너뛰기</button>
        <button id="payCancelButton" @click="closePopup">취소</button>
      </div>

      <div v-else-if="barcodeType === 'USER' && paymentType === 'BARCODE'">
        <img v-if="isPaid" src="../../img/check.svg" class="check-icon" />
        <h4 class="cafe-popup-header">
          {{ !isPaid ? "계산에 사용할 바코드를 스캔해 주세요." : "결제완료 되었습니다." }}
        </h4>
        <button @click="closePopup">{{ isPaid ? "확인" : "취소" }}</button>
      </div>

      <div v-else-if="barcodeType === 'DISABLED' && (paymentType === 'CASH' || paymentType === 'CC')">
        <img v-if="isPaid" src="../../img/check.svg" class="check-icon" />
        <h4 class="cafe-popup-header">{{ !isPaid ? "결제가 완료되었습니까?" : "시스템에 기록되었습니다." }}</h4>
        <div v-if="!isPaid">
          받은 금액 (Optional): $ {{ receivedAmount }}
          <div class="amt-btn-container">
            <button @click="updateReceivedAmount(1)" class="update-amt-btn">+$1</button>
            <button @click="updateReceivedAmount(5)" class="update-amt-btn">+$5</button>
            <button @click="updateReceivedAmount(10)" class="update-amt-btn">+$10</button>
            <button @click="updateReceivedAmount(20)" class="update-amt-btn">+$20</button>
            <button @click="updateReceivedAmount(50)" class="update-amt-btn">+$50</button>
            <button @click="updateReceivedAmount(-1)" class="update-amt-btn clear-btn">Clear</button>
          </div>
        </div>
        <div v-else-if="receivedAmount">거스름돈: $ {{ (receivedAmount - total).toFixed(2) }}</div>

        <div class="cafe-popup-button-container" v-if="!isPaid">
          <button id="makePayment" @click="makePayment">확인</button>
          <button id="closePopup" @click="closePopup">취소</button>
        </div>
        <button id="paidConfirmation" @click="closePopup" v-else>확인</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const receivedAmount = ref(0);
    return {
      receivedAmount,
    };
  },
  props: {
    total: {
      type: Number,
      required: true,
    },
    isPaid: {
      type: Boolean,
      required: true,
    },
    paymentType: {
      type: String,
      required: true,
    },
    barcodeType: {
      type: String,
      required: true,
    },
  },
  methods: {
    closePopup() {
      this.$emit("close-cafe-popup");
    },
    makePayment(e) {
      e.target.disabled = true;
      this.$emit("make-payment");
    },
    skipORderNumber() {
      this.$emit("skip-order-number");
    },
    updateReceivedAmount(amount) {
      if (amount === -1) {
        this.receivedAmount = 0;
      } else {
        this.receivedAmount += amount;
      }
    },
  },
};
</script>

<style>
.cafe-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.cafe-popup-header {
  margin-top: 20px;
  margin-bottom: 20px;
}

.cafe-popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
}

.cafe-popup-total {
  margin-bottom: 15px;
}

.cafe-popup-button-container {
  display: flex;
  gap: 10px;
}

button {
  width: 100%;
  padding: 10px 20px;
  background-color: #dc2f2f;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 15px;
}

.check-icon {
  margin: auto;
  display: block;
}

.amt-btn-container {
  display: flex;
  column-gap: 10px;
  margin-bottom: 20px;
}

.update-amt-btn {
  border-radius: 30px;
}

.clear-btn {
  background-color: #dc2f2f;
}
</style>
